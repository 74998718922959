import React from "react";
import { Card, Typography, Form, Input, Select, Checkbox, Button, Divider, Row, Col, Radio, Space } from "antd";

const { Title, Text } = Typography;
const { Option } = Select;

const BookingPage = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "'Arial', sans-serif" }}>
      <Title level={3}>Confirm Your Stay</Title>
      <Row gutter={[16, 16]}>
        {/* Left Column */}
        <Col xs={24} md={8}>
        <Card>
            <Title level={4}>Executive Room</Title>
            <Text>
              <strong>Check-in:</strong> Fri, Jan 19, 2025 | <strong>Check-out:</strong> Sat, Jan 20, 2025
            </Text>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <Text>Guests:</Text>
              <Text>1 Adult</Text>
            </Row>
            <Row justify="space-between">
              <Text>Total:</Text>
              <Text style={{ fontWeight: "bold" }}>TZS 130,079.25</Text>
            </Row>
          </Card>
        </Col>

        {/* Right Column */}
        <Col xs={24} md={16}>
          {/* Booking Summary */}


          {/* Enter Your Details */}
          <Card style={{ marginTop: "" }}>
            <Title level={5}>Enter your details</Title>
            <Form layout="vertical">
              <Form.Item label="First Name" name="firstName" required>
                <Input placeholder="Enter your first name" />
              </Form.Item>
              <Form.Item label="Last Name" name="lastName" required>
                <Input placeholder="Enter your last name" />
              </Form.Item>
              <Form.Item label="Email Address" name="email" required>
                <Input placeholder="Enter your email address" />
              </Form.Item>
              <Form.Item label="Phone Number" name="phone">
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <Form.Item>
                <Checkbox>Send me a confirmation via WhatsApp</Checkbox>
              </Form.Item>
            </Form>
          </Card>

          {/* Room Information */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Mixed Dormitory Room</Title>
            <Text>Pay nothing until January 17, 2025</Text>
            <Radio.Group defaultValue="mixedRoom" style={{ marginTop: "10px" }}>
              <Space direction="vertical">
                <Radio value="mixedRoom">1 room for TZS 130,079.25</Radio>
              </Space>
            </Radio.Group>
          </Card>

          {/* Add to Your Stay */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Add to your stay</Title>
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <Checkbox value="breakfast">Breakfast (TZS 15,000)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="airportTransfer">Airport Transfer (TZS 20,000)</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Card>

          {/* Special Requests */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Special Requests</Title>
            <Form.Item>
              <Input.TextArea placeholder="Write your special requests here" rows={3} />
            </Form.Item>
          </Card>

          {/* Arrival Time */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Your arrival time</Title>
            <Select placeholder="Select your estimated arrival time" style={{ width: "100%" }}>
              <Option value="before12">Before 12:00 PM</Option>
              <Option value="12to3">12:00 PM - 3:00 PM</Option>
              <Option value="after3">After 3:00 PM</Option>
            </Select>
          </Card>

          {/* Submit Button */}
          <Button type="primary" style={{ marginTop: "20px", width: "100%" }}>
            Finalize Booking
          </Button>

          {/* Footer Notes */}
          <div style={{ marginTop: "10px", fontSize: "12px" }}>
            <Text>
              By proceeding, you agree to the <a href="#">terms and conditions</a> and <a href="#">privacy policy</a>.
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BookingPage;
