import React, { useState } from "react";
import {
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Button,
  Switch,
} from "antd";
import {
  SearchOutlined,
  CalendarOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";

const { RangePicker } = DatePicker;

const SearchComponent = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [pets, setPets] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const handleSearch = () => {
    onSearch({ searchQuery, dateRange, adults, children, rooms, pets });
  };

  const guestMenu = (
    <Menu style={{ padding: "10px", width: "250px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <span>Adults</span>
        <div>
          <Button icon={<MinusOutlined />} onClick={() => setAdults((prev) => Math.max(0, prev - 1))} />
          <span style={{ margin: "0 10px" }}>{adults}</span>
          <Button icon={<PlusOutlined />} onClick={() => setAdults((prev) => prev + 1)} />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <span>Children</span>
        <div>
          <Button icon={<MinusOutlined />} onClick={() => setChildren((prev) => Math.max(0, prev - 1))} />
          <span style={{ margin: "0 10px" }}>{children}</span>
          <Button icon={<PlusOutlined />} onClick={() => setChildren((prev) => prev + 1)} />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <span>Rooms</span>
        <div>
          <Button icon={<MinusOutlined />} onClick={() => setRooms((prev) => Math.max(1, prev - 1))} />
          <span style={{ margin: "0 10px" }}>{rooms}</span>
          <Button icon={<PlusOutlined />} onClick={() => setRooms((prev) => prev + 1)} />
        </div>
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <span>Traveling with pets?</span>
        <Switch checked={pets} onChange={(checked) => setPets(checked)} />
      </div>
    </Menu>
  );
  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          borderRadius: "20px",
          backgroundColor: "#FFFFFF",
          border: "2px solid #FFC72C",
          width: "100%",
          maxWidth: "800px",
        }}
        className="search-container"
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search location"
          style={{ width: "100%", height: "50px", marginBottom: "10px" }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <RangePicker
          suffixIcon={<CalendarOutlined />}
          placeholder={["Check-in date", "Check-out date"]}
          style={{ width: "100%", height: "50px", marginBottom: "10px" }}
          onChange={(dates) => setDateRange(dates)}
        />
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "350px" }}>
            <Dropdown overlay={guestMenu} trigger={["click"]}>
              <Button
                style={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {`${adults} adults • ${children} children • ${rooms} room${rooms > 1 ? "s" : ""}`}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#005EB8",
              border: "none",
              color: "#FFFFFF",
              width: "120px",
              height: "50px",
              marginLeft: "10px",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
      <style>
        {`
          .search-container {
            flex-direction: row;
            justify-content: space-between;
          }

          @media (max-width: 767px) {
            .search-container {
              flex-direction: column;
              align-items: center;
              width: 70%;
              margin: 0 40px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SearchComponent;