import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'antd';
import 'antd/dist/antd.css';

const { Title, Text } = Typography;

const CustomCard = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ['Apartment', 'Hotel', 'Villa', 'Hostel'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 2000); // Change words every 2 seconds

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <Card
      style={{
        width: '100%',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
      }}
    >
      <Title level={1} style={{ fontSize: '2.5rem', margin: '10px 0' }}>Not satisfied with the "
        {words[currentWord]}" we sudgested for you?
      </Title>
      <Text type="secondary" style={{ fontSize: '1rem' }}>
        <a href="/HomeTest" style={{ textDecoration: 'none', color: '#1890ff' }}>
          Check all our Hotels here
        </a>
      </Text>
    </Card>
  );
};

export default CustomCard;
