// src/pages/BrowseHotels.jsx
import React from 'react';
import { Card, Row, Col, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import "../styles/BrowseHotels.less";

const { Meta } = Card;

const BrowseHotels = () => {
  const hotels = [
    { id: 1, name: 'Luxury Hotel', description: 'A 5-star luxury experience', image: 'https://via.placeholder.com/300x200' },
    { id: 2, name: 'Beachfront Resort', description: 'Relax by the ocean', image: 'https://via.placeholder.com/300x200' },
    { id: 3, name: 'City Center Inn', description: 'Perfect for business travelers', image: 'https://via.placeholder.com/300x200' },
    { id: 4, name: 'Mountain Lodge', description: 'Scenic views and fresh air', image: 'https://via.placeholder.com/300x200' },
  ];

  return (
    <div className="browse-hotels-page">
      <h1>Browse Hotels</h1>
      <Input
        size="large"
        placeholder="Search hotels"
        prefix={<SearchOutlined />}
        className="search-input"
      />
      <Row gutter={[16, 16]}>
        {hotels.map(hotel => (
          <Col xs={24} sm={12} md={8} lg={6} key={hotel.id}>
            <Card
              hoverable
              cover={<img alt={hotel.name} src={hotel.image} />}
              actions={[
                <Link to={`/hotel/${hotel.id}`}>
                  <Button type="primary">View Details</Button>
                </Link>
              ]}
            >
              <Meta title={hotel.name} description={hotel.description} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BrowseHotels;