import { SET_ACTIVE_TAB } from '../actions/tabAction'

const initialState = {
  activeTab: 'stays',
}

export const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload }
    default:
      return state
  }
}