import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Layout, Button, Select, Space, Tabs, Drawer, Menu } from 'antd'
import {
  // BedOutlined,
  GlobalOutlined,
  CarOutlined,
  HeartOutlined,
  QuestionCircleOutlined,
  DollarOutlined,
  PlusOutlined,
  RocketOutlined,
  // TaxiOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { setActiveTab } from '../userRedux/actions/tabAction'
import '../styles/BookingHeader.less'
import { useHistory } from 'react-router-dom'

const { Header } = Layout
const { Option } = Select
const { TabPane } = Tabs

const navItems = [
  { key: 'stays', icon: <RocketOutlined />, label: 'Stays' },
  { key: 'flights', icon: <RocketOutlined />, label: 'Flights' },
  { key: 'flight-hotel', icon: <PlusOutlined />, label: 'Flight + Hotel' },
  { key: 'car-rentals', icon: <CarOutlined />, label: 'Car rentals' },
  { key: 'attractions', icon: <HeartOutlined />, label: 'Attractions' },
  { key: 'airport-taxis', icon: <RocketOutlined />, label: 'Airport taxis' },
]

const BookingHeader = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeTab = useSelector((state) => state.tab.activeTab)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const handleTabChange = (key) => {
    dispatch(setActiveTab(key))
    setMobileMenuVisible(false)
  }

  const handleCurrencyChange = (value) => {
    console.log('Currency changed:', value)
  }

  const handleLanguageChange = (value) => {
    console.log('Language changed:', value)
  }

  const showMobileMenu = () => {
    setMobileMenuVisible(true)
  }

  const closeMobileMenu = () => {
    setMobileMenuVisible(false)
  }

  const renderMobileMenu = () => (
    <Drawer
      title="Menu"
      placement="left"
      onClose={closeMobileMenu}
      visible={mobileMenuVisible}
      className="mobile-menu-drawer"
    >
      {/* <Menu mode="vertical" selectedKeys={[activeTab]} onClick={({ key }) => handleTabChange(key)}>
        {navItems.map(item => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu> */}
      <div className="mobile-menu-actions">
        <Button type="primary" block onClick={() => dispatch(push('/signin'))}>
          Sign in
        </Button>
        <Button block onClick={() => dispatch(push('/signup'))}>
          Register
        </Button>
        <Button block>List your property</Button>
        <Select
          defaultValue="USD"
          onChange={handleCurrencyChange}
          className="currency-select"
          suffixIcon={<DollarOutlined />}
          style={{ width: '100%' }}
        >
          <Option value="USD">USD</Option>
          <Option value="EUR">EUR</Option>
          <Option value="GBP">GBP</Option>
        </Select>
        <Select
          defaultValue="EN"
          onChange={handleLanguageChange}
          className="language-select"
          suffixIcon={<GlobalOutlined />}
          style={{ width: '100%' }}
        >
          <Option value="EN">English</Option>
          <Option value="ES">Español</Option>
          <Option value="FR">Français</Option>
        </Select>
      </div>
    </Drawer>
  )

  return (
    <Header className="booking-header" style={{ backgroundColor: 'deepblue' }}>
      <div className="header-content">
        <div className="top-bar">
          <div className="logo" onClick={() => history.push('/')}>
            BongoHotel
          </div>
          <div className="header-actions">
            <Space className="action-items desktop-actions">
              <Select
                defaultValue="USD"
                onChange={handleCurrencyChange}
                className="currency-select"
                suffixIcon={<DollarOutlined />}
                bordered={false}
              >
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
                <Option value="GBP">GBP</Option>
              </Select>
              <Select
                defaultValue="EN"
                onChange={handleLanguageChange}
                className="language-select"
                suffixIcon={<GlobalOutlined />}
                bordered={false}
              >
                <Option value="EN">English</Option>
                <Option value="ES">Español</Option>
                <Option value="FR">Français</Option>
              </Select>
              <Button type="text">List your property</Button>
              <Button type="text" onClick={() => history.push("/signup")}>Register</Button>
              <Button type="primary" onClick={() => history.push("/signin")}>Sign in</Button>
            </Space>
            <Button className="mobile-menu-button" icon={<MenuOutlined />} onClick={showMobileMenu} />
          </div>
        </div>
      </div>
      {renderMobileMenu()}
    </Header>
  )
}

export default BookingHeader