import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  Row,
  Col,
  Rate,
  Checkbox,
  AutoComplete,
  DatePicker,
  Select,
  InputNumber,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { setActiveTab } from "../userRedux/actions/tabAction";
import "../styles/Home.less";
import axios_ from "../../../util/axios";
import Paragraph from "antd/lib/skeleton/Paragraph";
import HomeTest from "../../Home";
import ClientHero from "../../../components/ClientHero/index";
import Destination from "../../../components/Destination/destination";
import ByProperties from "../../../components/AllHotels/ByProperties/byProperties";
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import AllHotels from "../../../components/AllHotels/allhotels";

const { Title, Text, Link } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

// Dummy data for search suggestions
const staysSuggestions = [
  "Dar es salaam",
  "Zanzibar",
  "Arusha",
  "Mwanza",
  "Kilimanjaro",
  "New York",
  "London",
  "Paris",
  "Tokyo",
  "Sydney",
];
const flightsSuggestions = ["JFK", "LHR", "CDG", "NRT", "SYD"];
const carRentalsSuggestions = ["Hertz", "Avis", "Enterprise", "Budget", "Sixt"];
const attractionsSuggestions = [
  "Eiffel Tower",
  "Statue of Liberty",
  "Big Ben",
  "Tokyo Disneyland",
  "Sydney Opera House",
];

// Dummy data for hotels
const popularDestinations = [
  {
    id: 1,
    name: "Dar es Salaam",
    image: "../assets/images/dar.jpeg",
    height: 200,
    width: 300,
    properties: 1234,
    rating: 4.5,
  },
  {
    id: 2,
    name: "Zanzibar",
    image: "../assets/images/zanzibar.jpeg",
    properties: 2345,
    rating: 4.7,
  },
  {
    id: 3,
    name: "Arusha",
    image: "../assets/images/arusha.jpeg",
    properties: 3456,
    rating: 4.6,
  },
  {
    id: 4,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },
];

const deals = [
  {
    id: 1,
    title: "Luxury Resort & Spa",
    location: "Maldives",
    image: "/placeholder.svg?height=200&width=300",
    price: 299,
    rating: 4.8,
    reviews: 856,
  },
  {
    id: 2,
    title: "Mountain View Lodge",
    location: "Switzerland",
    image: "/placeholder.svg?height=200&width=300",
    price: 199,
    rating: 4.6,
    reviews: 654,
  },
  {
    id: 3,
    title: "Beachfront Villa",
    location: "Bali",
    image: "/placeholder.svg?height=200&width=300",
    price: 159,
    rating: 4.7,
    reviews: 432,
  },
];

const Home = () => {
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [hotels, setHotels] = useState([]);
  const activeTab = useSelector((state) => state.tab.activeTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab("stays"));
    }
  }, [dispatch, activeTab]);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const { data } = await axios_.get("/dashboard");
        // console.log(data)
        const images = data.map((hotel) =>
          hotel.images.map((image) => image.imageUrl)
        );
        setHotels(
          data.map((hotel, index) => ({
            ...hotel,
          }))
        );
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    fetchHotels();
  }, []);

  const handleHotelClick = (id) => {
    history.push(`/hotel/${id}`);
  };

  const renderSearchBox = () => {
    switch (activeTab) {
      // case "stays":
      //   return (
      //     <div className="search-form">
      //       <AutoComplete
      //         options={staysSuggestions.map((item) => ({ value: item }))}
      //         placeholder="Where are you going?"
      //         filterOption={(inputValue, option) =>
      //           option &&
      //           option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
      //             -1
      //         }
      //       />
      //       <RangePicker />
      //       <Select defaultValue="2 adults">
      //         <Option value="1 adult">1 adult</Option>
      //         <Option value="2 adults">2 adults</Option>
      //         <Option value="2 adults 1 child">2 adults, 1 child</Option>
      //       </Select>
      //       <Button type="primary" icon={<SearchOutlined />}>
      //         Search
      //       </Button>
      //     </div>
      //   );
      case "flights":
        return (
          <div className="search-form">
            <AutoComplete
              options={flightsSuggestions.map((item) => ({ value: item }))}
              placeholder="From"
              filterOption={(inputValue, option) =>
                option &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              }
            />
            <AutoComplete
              options={flightsSuggestions.map((item) => ({ value: item }))}
              placeholder="To"
              filterOption={(inputValue, option) =>
                option &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              }
            />
            <DatePicker />
            <Select defaultValue="Economy">
              <Option value="Economy">Economy</Option>
              <Option value="Business">Business</Option>
              <Option value="First">First Class</Option>
            </Select>
            <Button type="primary" icon={<SearchOutlined />}>
              Search Flights
            </Button>
          </div>
        );
      case "car-rentals":
        return (
          <div className="search-form">
            <AutoComplete
              options={carRentalsSuggestions.map((item) => ({ value: item }))}
              placeholder="Pick-up location"
              filterOption={(inputValue, option) =>
                option &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              }
            />
            <RangePicker />
            <Select defaultValue="Economy">
              <Option value="Economy">Economy</Option>
              <Option value="Compact">Compact</Option>
              <Option value="SUV">SUV</Option>
            </Select>
            <Button type="primary" icon={<SearchOutlined />}>
              Search Cars
            </Button>
          </div>
        );
      case "attractions":
        return (
          <div className="search-form">
            <AutoComplete
              options={attractionsSuggestions.map((item) => ({ value: item }))}
              placeholder="What would you like to do?"
              filterOption={(inputValue, option) =>
                option &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              }
            />
            <DatePicker />
            <InputNumber min={1} max={10} defaultValue={2} />
            <Button type="primary" icon={<SearchOutlined />}>
              Search Attractions
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const onSearch = (searchQuery) => {
    console.log(searchQuery);
    axios_.get(`/search?query=${searchQuery}`).then((res) => {
      console.log(res.data);
    });
    // history.push(`/search?query=${searchQuery}`);
  };

  return (
    <div className="home-content">
      <div className="hero-section">
        <ClientHero />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 3, // Ensure the text is above the video
          margin: "-40px", // Negative margin
        }}
      >
        <SearchComponent onSearch={onSearch} />{" "}
      </div>

      <div className="content-section">
        <Title level={2}>Popular destinations</Title>
        <Row gutter={[16, 16]}>
          {popularDestinations.map((destination) => (
            <Col xs={24} sm={12} md={6} key={destination.id}>
              <Card
                hoverable
                cover={<img alt={destination.name} src={destination.image} />}
                className="destination-card"
                onClick={() => handleHotelClick(destination.id)}
              >
                <Card.Meta
                  title={destination.name}
                  description={`${destination.properties} properties`}
                />
                <Rate
                  disabled
                  defaultValue={destination.rating}
                  className="destination-rating"
                />
              </Card>
            </Col>
          ))}
        </Row>

        <Title level={2} className="section-title">
          Deals for the weekend
        </Title>
        <Row gutter={[16, 16]}>
          {deals.map((deal) => (
            <Col xs={24} sm={12} md={8} key={deal.id}>
              <Card
                hoverable
                cover={<img alt={deal.title} src={deal.image} />}
                className="deal-card"
                onClick={() => handleHotelClick(deal.id)}
              >
                <Card.Meta
                  title={deal.title}
                  description={
                    <div>
                      <Text>{deal.location}</Text>
                      <div className="deal-rating">
                        <Rate disabled defaultValue={deal.rating} />
                        <Text className="review-count">
                          ({deal.reviews} reviews)
                        </Text>
                      </div>
                      <Text className="deal-price">
                        Starting from ${deal.price}
                      </Text>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div>{/* <ByProperties /> */}</div>
      <div>
        <Destination />
      </div>
      <div>
        <AllHotels />
      </div>
    </div>
  );
};

export default Home;
