import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GoogleOutlined, FacebookOutlined } from "@ant-design/icons";

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userGoogleSignIn,
  userFacebookSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/dashboard');
    }
  }, [showMessage, authUser, dispatch, history]);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  const handleGoogleSignIn = () => {
    dispatch(showAuthLoader());
    dispatch(userGoogleSignIn());
  };

  const handleFacebookSignIn = () => {
    dispatch(showAuthLoader());
    dispatch(userFacebookSignIn());
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <div className="gx-app-logo">
                <img style={{maxWidth:"100%"}} alt="example" src="/assets/images/logo-main.png"/>
              </div>
            </div>
            <div className="gx-app-logo">
              <h1>Sign In</h1>
              <p>Welcome back!</p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]} name="email">
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Checkbox>Remember Me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit" block>
                  Sign In
                </Button>
              </Form.Item>

              <Divider>Or</Divider>

              <Form.Item>
                <Button 
                  icon={<GoogleOutlined />}
                  onClick={handleGoogleSignIn}
                  block
                  style={{ marginBottom: 16 }}
                >
                  Sign in with Google
                </Button>
                <Button 
                  icon={<FacebookOutlined />}
                  onClick={handleFacebookSignIn}
                  block
                >
                  Sign in with Facebook
                </Button>
              </Form.Item>

              <div>
                <Button type="primary" className="gx-mb-0" onClick={() => history.push('/')}>Back</Button>
              </div>
            </Form>
          </div>

          {loader &&
            <div className="gx-loader-view">
              <CircularProgress/>
            </div>
          }
          {showMessage &&
            message.error(alertMessage.toString())
          }
        </div>
      </div>
    </div>
  );
};

export default SignIn;