import { takeLatest, put, fork, all } from 'redux-saga/effects'
import { SET_ACTIVE_TAB } from '../actions/tabAction'

function* handleTabChange(action) {
  // You can add additional logic here if needed
  yield put({ type: 'TAB_CHANGED', payload: action.payload })
}

export function* watchTabChange() {
  yield takeLatest(SET_ACTIVE_TAB, handleTabChange)
}


  export default function* rootSaga() {
    yield all([fork(watchTabChange)]);
  }