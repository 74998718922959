import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  Input,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Tooltip,
  Pagination,
  Image,
  Tabs,
  Dropdown,
  Menu,
  Switch, Rate, Tag
} from "antd";
import {
  EnvironmentOutlined,
  CompassOutlined,
  PictureOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
  SearchOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios_ from "../util/axios";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const BrowsingHotelPage = ({Allhotels}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  // State for hotels and loading state
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for enhanced search filters
  const [searchQuery, setSearchQuery] = useState(""); // Search query for location or hotel name
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [pets, setPets] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const [loadedImages,setLoadedImages] = useState({})

  const hotelsPerPage = 8;

  // Fetch hotels data from API
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios_.get("/dashboard"); // Replace with your API endpoint
        setHotels(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hotels:", error);
        setLoading(false);
      }
    };


    fetchHotels();
  }, []);

  useEffect(() => {
    const fetchHotelImages = async (hotelId) => {
      try {
        const response = await axios_.get(`/dashboard/hotel-images/${hotelId}`)
        const images = response.data
console.log(images)
        if (images.length > 0) {
          const imageResponse = await axios_.get(images[0].imageUrl, { responseType: 'blob' })
          const imageUrl = URL.createObjectURL(imageResponse.data)
          return { id: hotelId, imageUrl }
        }
        return { id: hotelId, imageUrl: '/placeholder.svg?height=200&width=300' }
      } catch (error) {
        console.error(`Error fetching images for hotel ${hotelId}:`, error)
        return { id: hotelId, imageUrl: '/placeholder.svg?height=200&width=300' }
      }
    }

    const loadAllHotelImages = async () => {
      const imagePromises = hotels.map(hotel => fetchHotelImages(hotel.id))
      const loadedImageUrls = await Promise.all(imagePromises)
      const imageUrlMap = loadedImageUrls.reduce((acc, { id, imageUrl }) => {
        acc[id] = imageUrl
        return acc
      }, {} )

      setLoadedImages(imageUrlMap)
    }

    loadAllHotelImages()

    return () => {
      Object.values(loadedImages).forEach(url => URL.revokeObjectURL(url))
    }
  }, [hotels])

  // Filter hotels based on enhanced search filters
  const filteredHotels = hotels.filter((hotel) =>
    hotel.address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedHotels = filteredHotels.slice(
    (currentPage - 1) * hotelsPerPage,
    currentPage * hotelsPerPage
  );

  const showModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalVisible(true);
  };

  const showDetailsModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsDetailsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsDetailsModalVisible(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };



  const guestMenu = (
    <Menu style={{ padding: "10px", width: "250px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Adults</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setAdults((prev) => Math.max(0, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{adults}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setAdults((prev) => prev + 1)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Children</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setChildren((prev) => Math.max(0, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{children}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setChildren((prev) => prev + 1)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Rooms</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setRooms((prev) => Math.max(1, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{rooms}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setRooms((prev) => prev + 1)}
          />
        </div>
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Traveling with pets?</span>
        <Switch checked={pets} onChange={(checked) => setPets(checked)} />
      </div>
      <Button
        type="primary"
        style={{ width: "100%" }}
        onClick={() => console.log("Done clicked!")}
      >
        Done
      </Button>
    </Menu>
  );


  return (
    <div style={{paddingTop:'30px'}}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            border: "2px solid #FFC72C",
          }}
        >
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search location"
            style={{ width: "200px", height: "50px", marginRight: "-2px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <RangePicker
            suffixIcon={<CalendarOutlined />}
            placeholder={["Check-in date", "Check-out date"]}
            style={{ width: "250px", height: "50px", marginRight: "-2px" }}
            onChange={(dates) => setDateRange(dates)}
          />
          <div style={{ paddingTop: "1rem", display: "flex" }}>
            <Dropdown overlay={guestMenu} trigger={["click"]}>
              <Button
                style={{
                  width: "350px",
                  height: "50px",
                  marginRight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {`${adults} adults • ${children} children • ${rooms} room${
                  rooms > 1 ? "s" : ""
                }`}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              style={{
                backgroundColor: "#005EB8",
                border: "none",
                color: "#FFFFFF",
                width: "120px",
                height: "50px",
              }}
              onClick={() => console.log("Search clicked!")}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div
        style={{ maxWidth: "100%", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Title level={2} className="text-center mb-8">
          Our Featured Hotels
        </Title>


        <Row gutter={[16, 16]}>
          {displayedHotels.map((hotel) => (
            <Col xs={24} md={8} sm={12} lg={6} key={hotel.id}>
              <Link to={`/hotel/${hotel.id}`}>
              <Card
                          hoverable
                          cover={
                            <Image
                              alt={hotel.name}
                              src={loadedImages[hotel.id] || '/placeholder.svg?height=200&width=300'}
                              fallback="/placeholder.svg?height=200&width=300"
                              preview={false}
                            />
                          }
                          className="hotel-card"

                        >
                  <Col xs={24} md={12}>
                    <Title level={3}>{hotel.name}</Title>
                    <Paragraph>
                      <EnvironmentOutlined /> {hotel.address}
                    </Paragraph>
                    <Paragraph>{hotel.description}</Paragraph>
                    <Paragraph>Property Type: {hotel.propertyType}</Paragraph>
                    <Paragraph strong style={{ fontSize: '16px', margin: '12px 0' }}>
          <strong>Starting from:{hotel.price_per_night}</strong> <span style={{ color: '#333' }}>TZS {hotel.price}</span>
        </Paragraph>
        <Paragraph style={{ margin: '8px 0' }}>
          <Tag color="blue" style={{ marginLeft: 8 }}>
            {hotel.reviews} reviews
          </Tag>
        </Paragraph>
                    <Space>
                      <Button type="primary" onClick={() => showModal(hotel)}>
                        Book Now
                      </Button>
                      {/* <Tooltip title="View on Google Maps">
                        <Button
                          icon={<CompassOutlined />}
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${hotel.location}`,
                              "_blank"
                            )
                          }
                        >
                          Map
                        </Button>
                      </Tooltip> */}
                    </Space>
                  </Col>
              </Card>
                              </Link>
            </Col>
          ))}
        </Row>

        <div className="flex justify-center mt-8">
          <Pagination
            current={currentPage}
            total={filteredHotels.length}
            pageSize={hotelsPerPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BrowsingHotelPage;
