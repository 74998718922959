import React from "react";
import { Layout } from "antd";
import BookingHeader from "./BookingHeader";
import "../styles/UnrestrictedLayout.less";
import ClientFooter from '../../../components/ClientFooter/index'

const { Content, Footer } = Layout;

const UnrestrictedLayout = React.memo(({ children }) => {
  return (
    <Layout className="gx-app-layout">
      <BookingHeader />
      <Content className={`gx-layout-content`}>
      <div className="scrollable-content">
          {children}
        </div>
        <Footer className="site-footer">
          {/* <ClientFooter /> */}
          Bongo Hotels ©{new Date().getFullYear()} E-Wallet Africa LTD
        </Footer>
      </Content>
    </Layout>
  );
});

UnrestrictedLayout.displayName = "UnrestrictedLayout";

export default UnrestrictedLayout;
