import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Typography, Space, Button, Image, Row, Col, Tabs, Tag,Carousel } from "antd";
import { EnvironmentOutlined, HomeOutlined, GlobalOutlined } from "@ant-design/icons";
import axios_ from "../../../util/axios";

const { Title, Paragraph } = Typography;

const HotelDetailsPage = () => {
  const { id } = useParams(); // Get hotel id from URL
  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        const response = await axios_.get(`/dashboard/${id}`); // Fetch specific hotel data
        setHotel(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
        setLoading(false);
      }
    };

    fetchHotelDetails();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!hotel) {
    return <p>Hotel not found.</p>;
  }

  // Smooth scrolling to target section
  const handleScroll = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (

      <div style={{ padding: "10px", maxWidth: "1800px", margin: "auto" }}>
        <Card>

          {/* Tabs for navigation */}
          <Tabs defaultActiveKey="1" onTabClick={handleScroll} size="large">
            <Tabs.TabPane tab="Overview" key="overview" />
            <Tabs.TabPane tab="Details" key="details" />
            <Tabs.TabPane tab="Gallery" key="gallery" />

            <Tabs.TabPane tab="Rooms" key="rooms" />
            <Tabs.TabPane tab="Reviews" key="reviews" />
            <Tabs.TabPane tab="Book Now" key="book" />
          </Tabs>

          {/* Overview Section */}
          <div id="overview" style={{ padding: "20px 0" }}>
            <Title level={3}>Overview</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Image src={hotel.images[0]?.imageUrl} alt={hotel.name} style={{ borderRadius: 8 }} />
              </Col>
              <Col span={12}>
                <Title level={4}>{hotel.name}</Title>
                <Paragraph>{hotel.description}</Paragraph>
                <Space direction="vertical">
                  <Paragraph><EnvironmentOutlined /> {hotel.address}, {hotel.location}</Paragraph>
                  <Paragraph><HomeOutlined /> {hotel.propertyType}</Paragraph>
                  <Paragraph><GlobalOutlined /> <a href={`https://${hotel.website}`} target="_blank" rel="noopener noreferrer">{hotel.website}</a></Paragraph>
                </Space>
              </Col>
            </Row>
          </div>
           {/* Details Section */}
           <div id="details" style={{ padding: "20px 0" }}>
            <Title level={3}>Hotel Details</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Paragraph><strong>Owner:</strong> {hotel.ownerName}</Paragraph>
                <Paragraph><strong>TIN Number:</strong> {hotel.tinNumber}</Paragraph>
                <Paragraph><strong>Business License:</strong> {hotel.businessLicence}</Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph><strong>Capacity:</strong> {hotel.maximumHotelCapacity} guests</Paragraph>
                <Paragraph><strong>Refund Policy:</strong> {hotel.refundPolicyAmount}</Paragraph>
                <Paragraph><strong>Booking Policy:</strong> {hotel.bookingPolicyAmount}</Paragraph>
              </Col>
            </Row>

            <div>
              <Title level={4}>Amenities</Title>
              {hotel.amenities.map((amenity) => (
                <Tag key={amenity.id} color="blue">{amenity.amenityName}</Tag>
              ))}
            </div>
            <div id="gallery" style={{ padding: "20px 0" }}>
            <Title level={3}>Gallery</Title>
            <Paragraph>Our Gallery</Paragraph>
            <p>We need api to display all images according to hotels</p>
          </div>
              {/* Rooms Section */}
          <div id="rooms" style={{ padding: "20px 0" }}>
            <Title level={3}>Rooms</Title>
            <Paragraph>No rooms available for this hotel.</Paragraph>
            <p>we are not sure of the correct room orientation for each hotel</p>
          </div>
            <div id="reviews" style={{ padding: "20px 0" }}>
            <Title level={3}>Our Reviews</Title>
            <Paragraph>No rooms available for this hotel.</Paragraph>
            <p>we need an api for writing and reading reviews according to the hotel</p>
          </div>
          <div id="book" style={{ padding: "20px 0" }}>
            <Title level={3}>Book Now</Title>
            <Paragraph>Satisfied with your selection?
            <a href="/BookHotel">Book now!</a>
            </Paragraph>
          </div>
</div>


        </Card>
      </div>

  );
};

export default HotelDetailsPage;
