import React from "react";
import BrowseHotel from "./BrowseHotel";

const Home = () => {
    return (
<>
<BrowseHotel />
</>
    );
};

export default Home;
